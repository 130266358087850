import React from 'react';
import { Toaster } from 'react-hot-toast';

import Routes from '../routes';
import { NotificationContextLayout } from '../hooks/NotificationProvider';
import { UserContextLayout } from '../hooks/UserProvider';
import { AssessmentContextLayout } from '../hooks/AssessmentProvider';
import AppRoleBar from './AppRoleBar';

const AppInitialScreen = () => {
	return (
		<NotificationContextLayout>
			<UserContextLayout>
				<AssessmentContextLayout>
					<Toaster
						position='top-right'
						toastOptions={{ className: 'wg-toaster' }}
						containerStyle={{
							top: 100,
						}}
					/>
					<AppRoleBar />
					<Routes />
				</AssessmentContextLayout>
			</UserContextLayout>
		</NotificationContextLayout>
	);
};

export default AppInitialScreen;

import React, { useEffect, useState } from 'react';
import { globalHistory, useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import { useUser } from './UserProvider';
import { setCookieIfPresent, updateAuthCookieExpiration } from '../services/UtilityService';
import { useCheckout } from "@ttg/ui-checkout-lib";

const loginPath = '/client/login/';
const checkoutPath = '/checkout-view';
const cartDetailsPath = '/shopping-cart-view';

export const AuthProvider = ({ permission, children }) => {
	const { user, loading, loaded, loadUser, hasPermission } = useUser();
	const [internalLoad, setInternalLoad] = useState(false);
	const isLoggedIn = React.useMemo(() => user?.id, [user]);
	const location = useLocation();
	const cookiePresent = setCookieIfPresent(location);
	const {
		customHooks: {
		paymentHook: { currentInvoice },
	}, } = useCheckout();

	useEffect(() => {
		return globalHistory.listen(() => {
			updateAuthCookieExpiration();
		});
	}, []);

	useEffect(() => {
		if (cookiePresent) {
			setInternalLoad(true);

			loadUser().finally(() => {
				navigate(location.pathname, { replace: true });
				setInternalLoad(false);
			});
		}
	}, []);

	const checkIfHasCartItems = () => {
		const keyXApplication = `usersCart-WG`;
		const cartDetails = localStorage.getItem(keyXApplication);
		if (!cartDetails) return false;
		const cartObject = JSON.parse(cartDetails)?.[isLoggedIn];

		return (
			cartObject &&
			cartObject?.cartItems.length > 0 &&
			!cartObject?.cartItems.find((item) => (item?.type === 'certification' && !item?.yourWorkingGeniuses)
				|| (item?.type === 'alumni_intensive' && !item?.metadata?.questions))
		);
	};

	if ((loading && !loaded) || internalLoad || cookiePresent) {
		return null;
	}

	if (!isLoggedIn && location.pathname !== loginPath) {
		const redirectParam = `${location.pathname}${location.search}`;
		navigate(redirectParam ? `${loginPath}?redirect=${encodeURIComponent(redirectParam)}` : loginPath);

		return null;
	}

	if (location.pathname === checkoutPath && !checkIfHasCartItems() && !currentInvoice) {
		navigate(cartDetailsPath);
		return null;
	}
	if (permission && !hasPermission(permission)) {
		navigate('/not-authorized');
		return null;
	}
	return children;
};

export const AuthContextLayout = ({ permission, children }) => <AuthProvider permission={permission}>{children}</AuthProvider>;
